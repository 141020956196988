$(document).ready(function(){

	$('#navbar-toggle').bind('click', function(){
		$('header').toggleClass('in');
	});

	$('header ul.nav').scrollingNav();

	$('pre').each(function(i, e) {hljs.highlightBlock(e)});

	if(!Modernizr.svg) {
		$("img[src$='.svg']").each(function(){
			fallback = (typeof $(this).data('fallback') !== 'undefined') ? $(this).data('fallback') : $(this).attr('src').substr(0, $(this).attr('src').lastIndexOf('.')) + '.png';
			console.log(fallback);
			$(this).attr('src',fallback);
		});
	}
	
	// Hubspot forms
	$('.hubspot-form').submit(function(e){
		e.preventDefault();
		var $form = $(this),
			portal_id = $form.data('portal'),
			form_id = $form.data('form'),
			$consent = $form.find('.consent');
		
		var data = {
			fields: $form.serializeArray(),
			context: {
				"pageUri": window.location.pageUri,
				"pageName": $(document).find("title").text()
			},
			legalConsentOptions: {
				consent: {
					consentToProcess: $consent.find('input')[0].checked,
					text: $consent.find('span.text').text()
				}
			}
		};
		
		$.ajax({
			url: 'https://api.hsforms.com/submissions/v3/integration/submit/' + portal_id + '/' + form_id,
			type: 'POST',
			contentType: 'application/json',
			data: JSON.stringify(data),
			dataType: 'json',
			success: function(response) {
				$form.append('<p class="alert alert-success">' + response.inlineMessage + '</p>');
				$form.trigger("reset");
			},
			error: function(response) {
				alert('Sorry, something has gone wrong. Please try again later.');
			}
		});
		
	});

});

(function($){

	var previousNavClass = '';

	var scroll_to = function(elem, speed, offset)
	{
		$('html, body').stop().animate({
			scrollTop: $(elem).offset().top - (offset - 1)
		}, speed, 'easeInOutExpo');
	}

	var set_active_item = function(elem, active)
	{
		$('li.active', elem).removeClass('active');
		$("a[href='#" + active + "']", elem).parent('li').addClass('active')
	}

	var set_nav_class = function(nav, elem)
	{
		var navClass = $(elem).data('scrolling-nav-class');

		if(navClass)
		{
			// Clear old class first
			nav.removeClass(previousNavClass);

			nav.addClass(navClass);
			previousNavClass = navClass;
		}
		else
		{
			nav.removeClass(previousNavClass);
			previousNavClass = '';
		}
	}

	$.fn.scrollingNav = function() {

		var $nav = $(this),
		$navWrapper = $('header'),
		navHeight = $nav.height(),
		total_sections = $('section').length,
		current_section = '';

		$(window).scroll(function(event){
			$('section').each(function(index){
				var position = $(window).scrollTop() + navHeight;
				if(position >= $(this).offset().top && position < $(this).offset().top + $(this).outerHeight())
				{
					set_active_item($nav, $(this).attr('id'));
					set_nav_class($navWrapper, $('#' + $(this).attr('id')));
				}
			});
		});

		$("a[href^='#']", $nav).bind('click', function(e){
			e.preventDefault();
			scroll_to($(this).attr('href'), 1500, navHeight);
		});

		return this;

	};

}(jQuery));
